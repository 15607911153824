import "../styles/AboutIntro.css";

export default function AboutIntro() {
  return (
    <div className="about-intro">
      <div className="text-container">
        <h1 className="heading">About Me</h1>
        <p className="paragraph">
          With a BSc in <span className="keyword">Computer Engineering</span>{" "}
          and an MSc in <span className="keyword">Data Science</span>, I bring
          expertise in <span className="keyword">software development</span> and{" "}
          <span className="keyword">data analytics</span>. My passion lies in
          developing robust, <span className="keyword">data-driven</span>{" "}
          applications that solve complex challenges and deliver measurable
          impact.
        </p>
        <p className="paragraph">
          In previous roles, I optimised systems, automated workflows, and
          delivered actionable insights. My work included developing{" "}
          <span className="keyword">full-stack applications</span>, managing{" "}
          <span className="keyword">databases</span>, and building{" "}
          <span className="keyword">machine learning models</span>. I also
          collaborated effectively within teams, driving efficiency and
          delivering meaningful results.
        </p>
        <p className="paragraph">
          I’m always eager to explore new tech trends and refine my skills
          through personal projects. Whether working independently or as part of
          a team, I’m driven by a commitment to{" "}
          <span className="keyword">innovation</span> and a focus on creating{" "}
          <span className="keyword">impactful solutions</span>.
        </p>
      </div>
      <img
        className="image"
        src={"images/about_intro.png"}
        alt="AboutIntro"
      ></img>
    </div>
  );
}
